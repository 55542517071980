import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Главная | Добро пожаловать в WheelEase Rentals
			</title>
			<meta name={"description"} content={"Плавные поездки, легкое вождение - WheelEase, ваше путешествие начинается здесь"} />
			<meta property={"og:title"} content={"Главная | Добро пожаловать в WheelEase Rentals"} />
			<meta property={"og:description"} content={"Плавные поездки, легкое вождение - WheelEase, ваше путешествие начинается здесь"} />
			<meta property={"og:image"} content={"https://groxina.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://groxina.com/img/3224646.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://groxina.com/img/3224646.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://groxina.com/img/3224646.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://groxina.com/img/3224646.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://groxina.com/img/3224646.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://groxina.com/img/3224646.jpg"} />
		</Helmet>
		<Components.Header />
		<Section
			md-justify-content="center"
			background="radial-gradient(50% 50% at 52.09% 50%,rgba(4, 8, 12, 0.1) 67.71%,rgba(4, 8, 12, 0.1) 100%),url(https://groxina.com/img/1.jpg) center center/cover no-repeat"
			min-height="90vh"
			sm-padding="40px 0 40px 0"
			quarkly-title="Hero-24"
			padding="0px 0 0px 0"
			md-align-items="center"
		>
			<Override
				slot="SectionContent"
				max-width="none"
				width="100%"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				flex-wrap="wrap"
				justify-content="flex-end"
				align-items="flex-end"
			/>
			<Box
				display="flex"
				align-items="flex-start"
				justify-content="center"
				padding="36px 48px 56px 48px"
				background="linear-gradient(120.99deg, #04080C 0%, rgba(25, 30, 34, 0.7) 100%)"
				flex-direction="column"
				width="30%"
				lg-width="50%"
				md-width="60%"
				sm-width="90%"
			>
				<Text
					text-transform="uppercase"
					letter-spacing="2px"
					margin="0px 0px 13px 0px"
					color="--lightD2"
					font="--base"
					lg-width="100%"
					lg-text-align="center"
				>
					автопрокат
				</Text>
				<Text
					lg-width="100%"
					lg-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="0px 0px 17px 0px"
					color="--light"
					font="--headline2"
				>
					WheelEase Rentals{" "}
				</Text>
				<Text
					color="--lightD2"
					font="--base"
					lg-width="100%"
					lg-text-align="center"
					margin="0px 0px 0px 0px"
				>
					Ваше приключение начинается с поворота ключа в WheelEase Rentals. Мы гордимся тем, что обеспечиваем бесперебойную аренду автомобилей, соответствующую всем требованиям вашего путешествия.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" margin="20px 0 0 0">
			Откройте для себя удобство аренды колес WheelEase
			</Text>
			<Text as="p" font="--lead" margin="20px" text-align="center">
			Отправляйтесь в следующее приключение с арендованными автомобилями WheelEase. Передвигайтесь по дорогам, используя широкий выбор автомобилей, от компактных машин для поездок по городу до просторных внедорожников для семейного отдыха. Мы стремимся предоставить вам надежный, чистый и готовый к вождению автомобиль, чтобы ваши путешествия были такими же плавными, как и захватывающие места назначения.
			</Text>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-12">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="100%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					position="relative"
					padding="0px 0px 70% 0px"
				>
					<Image
						width="100%"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						src="https://groxina.com/img/2.jpg"
						object-fit="cover"
						right={0}
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="100%"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Почему стоит выбрать WheelEase Rentals?
				</Text>
			</Box>
			<Box
				flex-direction="column"
				padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				width="33.3333%"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--grey"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Бронирование без лишних хлопот: Оцените простоту нашего процесса бронирования, разработанного для того, чтобы вы быстрее отправились в путь.
					<br />
					<br />
					Конкурентоспособные цены: Наслаждайтесь прозрачными ценами без скрытых платежей, что делает ваш опыт аренды простым и доступным.
				</Text>
			</Box>
			<Box
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				width="33.3333%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--grey"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Гарантия качества: Уверенно управляйте автомобилем, зная, что перед каждым прокатом он проходит тщательную проверку и очистку.
					<br />
					<br />
					Индивидуальный подход: Наша команда заботится о вашем комфорте и удобстве, предлагая гибкие варианты забора и возврата в соответствии с вашим графиком.
				</Text>
			</Box>
			<Box
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				display="flex"
				width="33.3333%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 60% 0px"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					width="100%"
					height="auto"
					overflow-y="hidden"
				>
					<Image
						bottom="0px"
						min-height="100%"
						src="https://groxina.com/img/3.jpg"
						display="block"
						width="100%"
						left={0}
						right={0}
						object-fit="cover"
						position="absolute"
						top="auto"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});